import React, { useEffect, useRef, useState } from "react"

// Libraries.
import { StaticImage } from "gatsby-plugin-image"

// Components.
import { Blured } from "@shared/Blured"
import Times from "./assets/times"

// Hooks.
import { useWindowSize } from "@hooks/useWindowSize"

// Assets.
import arrowLeft from "./assets/arrow-left.svg"
import arrowRight from "./assets/arrow-right.svg"

// Styles.
import {
  CursorPointerDiv,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  SubContainer,
  ModalBody,
  ModalMainImage,
  ModalRightImages,
  ModalImageSelector,
  ModalImageStyled,
  Text,
  Container,
  LeftImage,
  LeftVideo,
  Arrows,
  WrapperVideo,
  GlobalStyle,
} from "./styles"

import STRATEGY_ALTESA_12 from "../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/12_STRATEGY_ALTESA.gif"
import STRATEGY_ALTESA_13 from "../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/13_STRATEGY_ALTESA.gif"
import DIGITAL_TOYOTA_DICOMA_09 from "../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/09_DIGITAL_TOYOTA-DICOMA.mp4"
import MKT_VIRTUAL_PITCH_03 from "../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/03_MKT_VIRTUAL-PITCH.gif"
import BRANDING_GRUPOPPA_03 from "../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/03_BRANDING_GRUPOPPA.mp4"
import STRATEGY_GRUPOPPA_03 from "../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/03_STRATEGY_GRUPOPPA.mp4"
import MKT_TOPDOC_01 from "../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/01_MKT_TOPDOC.mp4"
import BRANDING_WMG_01 from "../Briefcase/project-images/01 BRANDING/20_BRANDING_WMG/01_BRANDING_WMG.mp4"
import STRATEGY_IKIGAI_01 from "../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/01_STRATEGY_IKIGAI.mp4"
import NOMI_1 from "../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/01_DIGITAL_NOMI-website.mp4"

const Nomi0 = props => (
  <ModalStruct
    text="This website was a part of a brand positioning strategy that I had the opportunity to develop from scratch. Its main goal was to present our company as a liable and experienced payroll service provider for big automotive foreign companies established in Mexico, especially Japanese, American, and German. Its overall look & feel is about car manufacturing facilities, technology, and people (employees), always trying to maintain a human and creative touch as a part of the company’s core values."
    header="NOMI"
    projectType="digitalDesign"
    name="NOMI"
    images={[
      <WrapperVideo>
        <LeftVideo loop autoPlay muted controls>
          <source src={NOMI_1} />
        </LeftVideo>
      </WrapperVideo>,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/03_DIGITAL_NOMI-website.jpg"
        alt="nomi 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/04_DIGITAL_NOMI-website.jpg"
        alt="nomi 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/05_DIGITAL_NOMI-website.jpg"
        alt="nomi 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/06_DIGITAL_NOMI-website.jpg"
        alt="nomi 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/07_DIGITAL_NOMI-website.jpg"
        alt="nomi 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/08_DIGITAL_NOMI-website.jpg"
        alt="nomi 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/09_DIGITAL_NOMI-website.jpg"
        alt="nomi 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/10_DIGITAL_NOMI-website.jpg"
        alt="nomi 9"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/02_DIGITAL_NOMI-website.jpg"
        alt="nomi 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/03_DIGITAL_NOMI-website.jpg"
        alt="nomi 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/04_DIGITAL_NOMI-website.jpg"
        alt="nomi 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/05_DIGITAL_NOMI-website.jpg"
        alt="nomi 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/06_DIGITAL_NOMI-website.jpg"
        alt="nomi 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/07_DIGITAL_NOMI-website.jpg"
        alt="nomi 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/08_DIGITAL_NOMI-website.jpg"
        alt="nomi 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/09_DIGITAL_NOMI-website.jpg"
        alt="nomi 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/10_DIGITAL_NOMI-website.jpg"
        alt="nomi 9"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const CityMedia0 = props => (
  <ModalStruct
    text="This project came to me as an opportunity to take a stand against Mexican OOH (out of home) advertising, which, I think, has never been more than visual pollution for the environment. If I’m going to get in your way, I thought, I might as well just put up a good show to entertain you and make your journey through Mexico City traffic a bit more pleasant. I came up with a USP (Unique Selling Proposition) that relied on putting up shows, no advertising, merely entertainment and captivating through the wow factor. "
    header="CITYMEDIA"
    projectType="branding"
    name="CITYMEDIA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/01_BRANDING_CITYMEDIA.jpg"
        alt="city media 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/02_BRANDING_CITYMEDIA.png"
        alt="city media 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/03_BRANDING_CITYMEDIA.png"
        alt="city media 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/04_BRANDING_CITYMEDIA.jpg"
        alt="city media 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/05_BRANDING_CITYMEDIA.jpg"
        alt="city media 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/06_BRANDING_CITYMEDIA.jpg"
        alt="city media 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/07_BRANDING_CITYMEDIA.jpg"
        alt="city media 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/08_BRANDING_CITYMEDIA.jpg"
        alt="city media 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/09_BRANDING_CITYMEDIA.jpg"
        alt="city media 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/10_BRANDING_CITYMEDIA.jpg"
        alt="city media 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/11_BRANDING_CITYMEDIA.jpg"
        alt="city media 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/12_BRANDING_CITYMEDIA.jpg"
        alt="city media 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/13_BRANDING_CITYMEDIA.jpg"
        alt="city media 13"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/14_BRANDING_CITYMEDIA.jpg"
        alt="city media 14"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/01_BRANDING_CITYMEDIA.jpg"
        alt="city media 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/02_BRANDING_CITYMEDIA.png"
        alt="city media 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/03_BRANDING_CITYMEDIA.png"
        alt="city media 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/04_BRANDING_CITYMEDIA.jpg"
        alt="city media 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/05_BRANDING_CITYMEDIA.jpg"
        alt="city media 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/06_BRANDING_CITYMEDIA.jpg"
        alt="city media 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/07_BRANDING_CITYMEDIA.jpg"
        alt="city media 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/08_BRANDING_CITYMEDIA.jpg"
        alt="city media 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/09_BRANDING_CITYMEDIA.jpg"
        alt="city media 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/10_BRANDING_CITYMEDIA.jpg"
        alt="city media 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/11_BRANDING_CITYMEDIA.jpg"
        alt="city media 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/12_BRANDING_CITYMEDIA.jpg"
        alt="city media 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/13_BRANDING_CITYMEDIA.jpg"
        alt="city media 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/02_BRANDING_CITYMEDIA/14_BRANDING_CITYMEDIA.jpg"
        alt="city media 14"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Altesa0 = props => (
  <ModalStruct
    text="During pandemic times and amid forced layoffs, in a 3rd world economy where salaries aren’t a source of motivation, my team and I manage to certify Altesa, the largest ice cream cone producer in Latin America, as a Great Place to Work®. In hindsight, I believe this achievement is more of an example of how a good internal communication strategy can change the employees' perception of a company and its leaders than a profound change in the work environment. However, that was something to leverage to inspire and motivate employees, especially in such hard times like Covid-19’s peak year."
    header="ALTESA"
    projectType="strategy"
    name="ALTESA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/01_STRATEGY_ALTESA.jpg"
        alt="altesa 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/02_STRATEGY_ALTESA.jpg"
        alt="altesa 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/03_STRATEGY_ALTESA.jpg"
        alt="altesa 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/04_STRATEGY_ALTESA.jpg"
        alt="altesa 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/05_STRATEGY_ALTESA.jpg"
        alt="altesa 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/06_STRATEGY_ALTESA.jpg"
        alt="altesa 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/07_STRATEGY_ALTESA.jpg"
        alt="altesa 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/08_STRATEGY_ALTESA.jpg"
        alt="altesa 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/09_STRATEGY_ALTESA.jpg"
        alt="altesa 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/10_STRATEGY_ALTESA.jpg"
        alt="altesa 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/11_STRATEGY_ALTESA.png"
        alt="altesa 11"
        layout="fullWidth"
      />,
      <LeftImage src={STRATEGY_ALTESA_12} alt="altesa 12" />,
      <LeftImage src={STRATEGY_ALTESA_13} alt="altesa 13" />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/14_STRATEGY_ALTESA.jpg"
        alt="altesa 14"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/15_STRATEGY_ALTESA.jpg"
        alt="altesa 15"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/01_STRATEGY_ALTESA.jpg"
        alt="altesa 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/02_STRATEGY_ALTESA.jpg"
        alt="altesa 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/03_STRATEGY_ALTESA.jpg"
        alt="altesa 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/04_STRATEGY_ALTESA.jpg"
        alt="altesa 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/05_STRATEGY_ALTESA.jpg"
        alt="altesa 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/06_STRATEGY_ALTESA.jpg"
        alt="altesa 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/07_STRATEGY_ALTESA.jpg"
        alt="altesa 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/08_STRATEGY_ALTESA.jpg"
        alt="altesa 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/09_STRATEGY_ALTESA.jpg"
        alt="altesa 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/10_STRATEGY_ALTESA.jpg"
        alt="altesa 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/11_STRATEGY_ALTESA.png"
        alt="altesa 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/12_STRATEGY_ALTESA.gif"
        alt="altesa 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/13_STRATEGY_ALTESA.gif"
        alt="altesa 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/14_STRATEGY_ALTESA.jpg"
        alt="altesa 14"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/03_STRATEGY_ALTESA/15_STRATEGY_ALTESA.jpg"
        alt="altesa 15"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const TopDoc0 = props => (
  <ModalStruct
    text="For the first massive project I was responsible for, I had to come up with something spectacular, so I roamed around the idea of supernatural doctors, given the nature of the application, to be the image of the techno-medical brand. This was also a homage (before Covid-19) to the countless hours of effort and sacrifice the doctors put into caring for their patients. Like superheroes, they would have several superpowers thanks to modern technology and be able to take care of/monitor their patients remotely as if they were always right next to them."
    header="TOPDOC"
    projectType="branding"
    name="TOPDOC"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/01_BRANDING_TOPDOC.jpg"
        alt="topdoc 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/02_BRANDING_TOPDOC.png"
        alt="topdoc 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/03_BRANDING_TOPDOC.jpg"
        alt="topdoc 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/04_BRANDING_TOPDOC.jpg"
        alt="topdoc 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/05_BRANDING_TOPDOC.jpg"
        alt="topdoc 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/06_BRANDING_TOPDOC.jpg"
        alt="topdoc 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/07_BRANDING_TOPDOC.png"
        alt="topdoc 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/08_BRANDING_TOPDOC.png"
        alt="topdoc 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/09_BRANDING_TOPDOC.png"
        alt="topdoc 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/10_BRANDING_TOPDOC.png"
        alt="topdoc 10"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/01_BRANDING_TOPDOC.jpg"
        alt="topdoc 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/02_BRANDING_TOPDOC.png"
        alt="topdoc 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/03_BRANDING_TOPDOC.jpg"
        alt="topdoc 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/04_BRANDING_TOPDOC.jpg"
        alt="topdoc 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/05_BRANDING_TOPDOC.jpg"
        alt="topdoc 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/06_BRANDING_TOPDOC.jpg"
        alt="topdoc 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/07_BRANDING_TOPDOC.png"
        alt="topdoc 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/08_BRANDING_TOPDOC.png"
        alt="topdoc 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/09_BRANDING_TOPDOC.png"
        alt="topdoc 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/04_BRANDING_TOPDOC/10_BRANDING_TOPDOC.png"
        alt="topdoc 10"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Nomi1 = props => (
  <ModalStruct
    text="As a payroll service provider, we handle much personal information from our client's employees, which is helpful for them in so many ways that we figured we needed to provide them a secure and easy way to access and manage it. By doing this, we also lowered the workload of the HR team because employees no longer had to come to them for clarifications regarding their information. Since this application user type is employees and not customers, competitors’ products don’t pay too much effort into the UX/UI design. I saw that as an edge and focused on trying to give the users a friendly interface and smooth user experience. I was a product manager and the UX/UI designer for this project."
    header="NOMI"
    projectType="digitalDesign"
    name="NOMI"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/01_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/02_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/03_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/04_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/05_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/06_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/07_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/08_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/09_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/10_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/11_DIGITAL_NOMI-MYCOMPANY.png"
        alt="nomi 11"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/01_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/02_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/03_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/04_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/05_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/06_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/07_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/08_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/09_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/10_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/05_DIGITAL_NOMI-MYCOMPANY/11_DIGITAL_NOMI-MYCOMPANY.png"
        alt="nomi 11"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Nomi2 = props => (
  <ModalStruct
    text="As part of the payroll service implementation, we needed a way to report all employee consumptions at the staff canteen into our payroll system since they were to be discounted via payroll. A significant challenge was that the validation response needed to be immediate; otherwise, hundreds of employees would be stalled waiting in line (Because of Covid-19, meals were no longer served on the spot; they were pre-packed and just handed on to the employees as they passed their I.D. Badges by the RFDI lector). This would’ve been easier if the system was local, but since employees' information needed to be updated daily from the HR system’s database, the app needed to be web-based. We strived but managed to take the response time under a second. I was a product manager and the UX/UI designer for this project."
    header="NOMI"
    projectType="digitalDesign"
    name="NOMI"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/01_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/02_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/03_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/04_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/05_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/06_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/07_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/08_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 8"
        layout="fullWidth"
      />,
      <WrapperVideo>
        <LeftVideo loop autoPlay muted controls>
          <source src={DIGITAL_TOYOTA_DICOMA_09} />
        </LeftVideo>
      </WrapperVideo>,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/10_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/11_DIGITAL_TOYOTA-DICOMA.png"
        alt="NOMI 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/12_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/13_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 13"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/14_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 14"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/01_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/02_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/03_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/04_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/05_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/06_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/07_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/08_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/09_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/10_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/11_DIGITAL_TOYOTA-DICOMA.png"
        alt="NOMI 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/12_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/13_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/14_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="NOMI 14"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Nomi3 = props => (
  <ModalStruct
    text="Numbers aren’t fun (unless you’re a math teacher or an accountant), but it’s ok; they are not meant to be. That does not imply that financial/accounting digital products must be dull & squared nor intimidating, as most are. From a human-centered design perspective, I noticed that a rough interface and lack of validation points were the leading causes of human mistakes. As a payroll service provider for big transnationals, we must minimize every possible risk of making errors. Our payroll specialists know this, which puts significant pressure on them. So, we tried to release some of that pressure by providing them with a friendly-trustworthy tool that they can rely on to manage sensitive figures and employee information. I was a product manager and the UX/UI designer for this project."
    header="NOMI"
    projectType="digitalDesign"
    name="NOMI"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/01_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/02_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/03_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/04_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/05_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/06_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/07_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/08_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/09_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/10_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/11_DIGITAL_NOMI-INTEGRITY.png"
        alt="NOMI 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/12_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/13_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 13"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/01_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/02_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/03_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/04_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/05_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/06_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/07_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/08_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/09_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/10_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/11_DIGITAL_NOMI-INTEGRITY.png"
        alt="NOMI 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/12_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/13_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="NOMI 13"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Altesa2 = props => (
  <ModalStruct
    text="Altesa’s whole brand image was based on the idea of telling the company story and achievements in a fun/illustrative way that was easy to be understood and remembered, foremost by its employees (due to low levels of credibility and pride shown in previous Great Place to Work surveys). The idea of removing the colors from the package design, using only black ink on a white background for the illustrations, came as a discreet solution for blending in the big black signs in compliance with a recently approved law that demands to warn against sugar and some other ingredients consumption."
    header="ALTESA"
    projectType="branding"
    name="ALTESA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/01_BRANDING_ALTESA.jpg"
        alt="ALTESA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/02_BRANDING_ALTESA.jpg"
        alt="ALTESA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/03_BRANDING_ALTESA.jpg"
        alt="ALTESA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/04_BRANDING_ALTESA.jpg"
        alt="ALTESA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/05_BRANDING_ALTESA.png"
        alt="ALTESA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/06_BRANDING_ALTESA.jpg"
        alt="ALTESA 6"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/01_BRANDING_ALTESA.jpg"
        alt="ALTESA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/02_BRANDING_ALTESA.jpg"
        alt="ALTESA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/03_BRANDING_ALTESA.jpg"
        alt="ALTESA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/04_BRANDING_ALTESA.jpg"
        alt="ALTESA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/05_BRANDING_ALTESA.png"
        alt="ALTESA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/08_BRANDING_ALTESA/06_BRANDING_ALTESA.jpg"
        alt="ALTESA 6"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Citymedia = props => (
  <ModalStruct
    text="This website’s primary purpose was to serve as a digital catalog to display all the available billboards managed by the company. So, my team and I photographed each of the +150 billboards, most of them on-site and some through google maps, to display them on the website, but also to convert the images into editable mock-ups so that the sales team members could show prospects and clients their advertising campaigns on CITYMEDIA’s locations. Another exciting feature was the possibility to find and pin in google maps each available location and to request a quote using an automated quoting system."
    header="CITYMEDIA"
    projectType="digitalDesign"
    name="CITYMEDIA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/01_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/02_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/03_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/04_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/05_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/06_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/07_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/08_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 8"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/01_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/02_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/03_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/04_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/05_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/06_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/07_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/08_DIGITAL_CITYMEDIA-website.jpg"
        alt="CITYMEDIA 8"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ikigai = props => (
  <ModalStruct
    text="The Brand IKIGAI system is my method of developing brand strategies. It came naturally after years of designing brand images since I realized that a lot of information (input) is needed to create an authentic and engaging brand. You can’t just make a unique selling proposition up or sense it… You need to dig and obtain raw material to create a solid argument that permeates consistently in all branding assets. The main idea of the methodology consists of aligning the company’s IKIGAI by introspectively analyzing its leaders and putting into words and graphics what comes out of their minds and hearts."
    header="IKIGAI"
    projectType="strategy"
    name="IKIGAI"
    images={[
      <WrapperVideo>
        <LeftVideo loop autoPlay>
          <source src={STRATEGY_IKIGAI_01} />
        </LeftVideo>
      </WrapperVideo>,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/02_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/03_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/04_STRATEGY_IKIGAI.png"
        alt="IKIGAI 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/05_STRATEGY_IKIGAI.png"
        alt="IKIGAI 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/06_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/07_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/08_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/09_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/10_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/11_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 11"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/01_STRATEGY_IKIGAI(thumbnail).jpg"
        alt="IKIGAI 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/02_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/03_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/04_STRATEGY_IKIGAI.png"
        alt="IKIGAI 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/05_STRATEGY_IKIGAI.png"
        alt="IKIGAI 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/06_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/07_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/08_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/09_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/10_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/10_STRATEGY_IKIGAI/11_STRATEGY_IKIGAI.jpg"
        alt="IKIGAI 11"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ppa0 = props => (
  <ModalStruct
    text="2019 was the year of Grupo PPA’s 10th anniversary, so they wanted to celebrate it with a new office, a new brand image, a big event, and a Great Place To Work certification®. Management had made it clear that they wished to have all nearly 50 employees involved. So, I figured that the best way to proceed was with interactive dynamics involving creativity, teamwork, fun, and every employee. I designed an internal communication strategy in the form of a rally, which allowed integration and teamwork to be the protagonists of the process. Every two weeks, we launched a new challenge, and a different part of the new office was decorated, revealing pieces of the new branding."
    header="GRUPO PPA"
    projectType="strategy"
    name="GRUPO PPA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/01_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/02_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 2"
        layout="fullWidth"
      />,
      <WrapperVideo>
        <LeftVideo loop autoPlay>
          <source src={STRATEGY_GRUPOPPA_03} alt="GRUPO PPA 3" />
        </LeftVideo>
      </WrapperVideo>,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/04_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/05_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/06_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/07_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/08_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/09_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/10_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/11_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/12_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/13_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 13"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/14_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 14"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/15_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 15"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/01_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/02_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/03_STRATEGY_GRUPOPPA(thumbnail).jpg"
        alt="GRUPO PPA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/04_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/05_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/06_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/07_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/08_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/09_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/10_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/11_STRATEGY_GRUPOPPA.png"
        alt="GRUPO PPA 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/12_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/13_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/14_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 14"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/15_STRATEGY_GRUPOPPA.jpg"
        alt="GRUPO PPA 15"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Nomi = props => (
  <ModalStruct
    text="In compliance with a new SAT (Mexico's IRS) regulation, all pay stubs must be certified, sent to employees, and safely stored. As the payroll service provider for TOYOTA, we needed to create a comprehensive and secure solution for its Mexican entities. So, I was responsible for the analysis, definition, design, and management of a web-based Fiscal Vault that performed the functions above and summarized information that then needed to be encrypted and safely transferred into their accounting system (SAP), providing extra validation points and a more straightforward safer way to manage pay stubs. I was a product manager and the UX/UI designer for this project."
    header="NOMI"
    projectType="digitalDesign"
    name="NOMI"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/01_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/02_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/03_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/04_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/05_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/06_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/07_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/08_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/09_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/10_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 10"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/01_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/02_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/03_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/04_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/05_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/06_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/07_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/08_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/09_DIGITAL_NOMI-FISCALVAULT.png"
        alt="NOMI 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/10_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="NOMI 10"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ppa1 = props => (
  <ModalStruct
    text="To celebrate its 10th anniversary, management wanted to capture the achievements and strife that turned the company into what it is now. After the introspection phase of my Brand IKIGAI Strategy, my conclusions were that the team's ADAPTABILITY to everchanging conditions was their main reason for success, so the whole brand story runs around the multiple adaptation features of a chameleon living in different environments according to nature’s five elements. Due to so many constant changes, sometimes it’s hard for employees not to feel lost. So, I came up with the idea of creating a TOTEM, something to make them feel protected and accompanied... to make decisions, to have courage and confidence in themselves because it reminds them of whom They are, what They believe in, and where They belong."
    header="GRUPO PPA"
    projectType="branding"
    name="GRUPO PPA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/01_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/02_BRANDING_GRUPOPPA.png"
        alt="GRUPO PPA 2"
        layout="fullWidth"
      />,
      <WrapperVideo>
        <LeftVideo loop autoPlay>
          <source src={BRANDING_GRUPOPPA_03} alt="GRUPO PPA 3" />
        </LeftVideo>
      </WrapperVideo>,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/04_BRANDING_GRUPOPPA.png"
        alt="GRUPO PPA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/05_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/06_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/07_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/08_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/09_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 9"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/01_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/02_BRANDING_GRUPOPPA.png"
        alt="GRUPO PPA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/03_BRANDING_GRUPOPPA.png"
        alt="GRUPO PPA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/04_BRANDING_GRUPOPPA.png"
        alt="GRUPO PPA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/05_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/06_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/07_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/08_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/13_BRANDING_GRUPOPPA/09_BRANDING_GRUPOPPA.jpg"
        alt="GRUPO PPA 9"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Pich = props => (
  <ModalStruct
    text="Due to the Covid-19 lockdown, pitching clients or prospects at their offices was no longer an option, but they still needed to be amazed. I came up with an attractive and feasible solution based on the nature of a virtual conference: LIVE BROADCASTING EFFECTS. Through specialized software (OBS Studio), motion graphics, and storytelling, I created entertaining and compelling material that gave the presenter confidence regardless of the drawbacks of making an online sales pitch. This not only caught the eye of our audience, but it indeed soared our sales team's closing rate."
    header="VIRTUAL PITCH"
    projectType="marketingSales"
    name="VIRTUAL PITCH"
    images={[
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/01_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/02_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 2"
        layout="fullWidth"
      />,
      <LeftImage src={MKT_VIRTUAL_PITCH_03} alt="VIRTUAL PITCH 3" />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/04_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 4"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/01_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/02_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/03_MKT_VIRTUAL-PITCH.gif"
        alt="VIRTUAL PITCH 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/14_MKT_VIRTUAL-PITCH/04_MKT_VIRTUAL-PITCH.jpg"
        alt="VIRTUAL PITCH 4"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const TodDoc2 = props => (
  <ModalStruct
    text="One of my long-time clients, a health provider, came to me because they wanted to enter the digital business and launch a medical app, it wasn’t clear what the scope would be and what features would it have, but they wanted to exploit their health professional’s network and turn it into some “uber for doctors” (it sounds out-of-date now, but this was early 2016). So, I put up a team, ran some polls throughout their medical network, and came up with a novel, ambitious concept meant to be a shared-economy platform where health professionals could offer their services and patients could find any specialist and keep good track of their health. The concept, the features, the Branding, the UX/UI, and the digital marketing strategy to validate the MVP was all my creation."
    header="TOPDOC"
    projectType="digitalDesign"
    name="TOPDOC"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/01_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/02_DIGITAL_TOPDOC.png"
        alt="TOPDOC 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/03_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/04_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/05_DIGITAL_TOPDOC.png"
        alt="TOPDOC 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/06_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/07_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/08_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/09_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/10_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/11_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/12_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 12"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/01_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/02_DIGITAL_TOPDOC.png"
        alt="TOPDOC 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/03_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/04_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/05_DIGITAL_TOPDOC.png"
        alt="TOPDOC 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/06_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/07_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/08_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/09_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/10_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/11_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/12_DIGITAL_TOPDOC.jpg"
        alt="TOPDOC 12"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Media = props => (
  <ModalStruct
    text="As part of an inbound marketing strategy meant to bring in prospects without needing a salesman involved in the process until the lead became an opportunity, I designed and managed the development of an online automated quoting system that shows the exact location of all the billboards, surroundings, and unique features. The user can select their preferred locations by an individual billboard or complete bridges (with multiple billboards) and visualize an estimate for that selection; if they are interested in receiving a quote in pdf, they can provide their contact information, and both the user and a sales team member receive an email with the detailed quote. I was a product manager and the UX/UI designer for this project."
    header="CITY MEDIA"
    projectType="digitalDesign"
    name="CITY MEDIA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/01_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/02_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/03_DIGITAL_CITYMEDIA-COTIZADOR.png"
        alt="CITY MEDIA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/04_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/05_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/06_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 6"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/01_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/02_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/03_DIGITAL_CITYMEDIA-COTIZADOR.png"
        alt="CITY MEDIA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/04_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/05_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/06_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="CITY MEDIA 6"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ppa2 = props => (
  <ModalStruct
    text="After constant growth, the company needed a bigger space to fit its increasing team and a better image toward its clients. So, the owner asked me if I could take care of remodeling (since I’m not an architect but had worked on the company’s branding for years), and I thought, well, there’s nothing structural to do, it's only interior design, and I’m sure I can permeate the brand image on it, so, let’s do it! And I put myself to work on the concept and the design; I hired a contractor who had worked with me before to remodel my own office… We tore down the place and, within two months, we gave the client a brand-new office, with “floating desks” and everything!"
    header="GRUPO PPA"
    projectType="branding"
    name="GRUPO PPA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/01_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/02_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/03_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/04_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/05_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/06_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/07_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/08_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/09_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/10_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/11_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/12_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/13_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 13"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/14_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 14"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/15_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 15"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/01_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/02_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/03_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/04_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/05_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/06_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/07_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/08_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/09_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/10_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/11_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/12_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/13_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/14_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 14"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/15_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="GRUPO PPA 15"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ppa3 = props => (
  <ModalStruct
    text="This website needed to be the icing on the cake of a brand strategy developed to celebrate the company’s 10th anniversary. Its main goal was to depict the excellent work environment that has distinguished the company for years and, at the same time, to attract potential new talent. We used actual employees for the photographs and even a corporate video created by them as part of an integration dynamic."
    header="GRUPO PPA"
    projectType="digitalDesign"
    name="GRUPO PPA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/01_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/02_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/03_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/04_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/05_DIGITAL_GRUPOPPA-WEBSITE.png"
        alt="GRUPO PPA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/06_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/07_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 7"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/01_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/02_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/03_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/04_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/05_DIGITAL_GRUPOPPA-WEBSITE.png"
        alt="GRUPO PPA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/06_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/07_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="GRUPO PPA 7"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const TocDoc = props => (
  <ModalStruct
    text="To create a new concept for health services that back then hadn’t been proven to work, first, we had to validate and measure the level of interest within the medical community to see if they were willing to pay a subscription to be a part of our doctor’s network and offer their services on Topdoc. I made a landing page, graphic content, a spot for YouTube and Facebook, and ran an ad campaign for two weeks with a 1,000 USD budget. We ended up with 190 signups that, in theory, would turn into a 19,000 USD yearly revenue."
    header="TOPDOC"
    projectType="marketingSales"
    name="TOPDOC"
    images={[
      <WrapperVideo>
        <LeftVideo loop autoPlay muted controls>
          <source src={MKT_TOPDOC_01} alt="GRUPO PPA 3" />
        </LeftVideo>
      </WrapperVideo>,

      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/02_MKT_TOPDOC.jpg"
        alt="TOPDOC 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/03_MKT_TOPDOC.jpg"
        alt="TOPDOC 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/04_MKT_TOPDOC.jpg"
        alt="TOPDOC 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/05_MKT_TOPDOC.png"
        alt="TOPDOC 5"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/01_MKT_TOPDOC(thumbnail).jpg"
        alt="TOPDOC 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/02_MKT_TOPDOC.jpg"
        alt="TOPDOC 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/03_MKT_TOPDOC.jpg"
        alt="TOPDOC 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/04_MKT_TOPDOC.jpg"
        alt="TOPDOC 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/03 MKT/19_MKT_TOPDOC/05_MKT_TOPDOC.png"
        alt="TOPDOC 5"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Wmg = props => (
  <ModalStruct
    text="This video was one of my first efforts to communicate the importance of branding to potential clients (primarily SMB owners and entrepreneurs). As an entrepreneur myself, I realized that the more professional your business looks, the more successful it will be, and that is through brand image consistency. I wanted to tell the story of an innocent (but visionary) kid who started his own business. Regardless of his enthusiasm, it wouldn’t take off until he called my guys and me (WMG) to make his lemonade stand look super-professional. Back then, our slogan was: “If you want your small business to become a great company, first, it must look like one.” (It worked better in Spanish…)"
    header="WMG"
    projectType="branding"
    name="WMG"
    images={[
      <WrapperVideo>
        <LeftVideo loop autoPlay muted controls>
          <source src={BRANDING_WMG_01} alt="GRUPO PPA 3" />
        </LeftVideo>
      </WrapperVideo>,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/20_BRANDING_WMG/01_BRANDING_WMG(thumbnail).jpg"
        alt="WMG 1"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Vensi = props => (
  <ModalStruct
    text="In 2013 I was starting to build a client portfolio for my small agency. I was young and inexperienced, but I compensated for that with fresh ideas and an honest will to offer a good service. An important startup trusted me to design a new look & feel and apply +1,200ft² of vinyl on their one-year-old magnificent-renown-architect-designed mostly made of glass offices. We accidentally scratched various 35ft² tempered glass panels that I had to replace, costing me more than I had charged them for the whole project ☹. They didn’t change the look & feel a year after as they planned to do it every year; My design lasted several years (I guess they liked it a lot). In the end, I lost money, but I gained experience and was very proud of our work and service."
    header="VENSI"
    projectType="branding"
    name="VENSI"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/01_BRANDING_VENSI.jpg"
        alt="VENSI 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/02_BRANDING_VENSI.jpg"
        alt="VENSI 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/03_BRANDING_VENSI.jpg"
        alt="VENSI 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/04_BRANDING_VENSI.jpg"
        alt="VENSI 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/05_BRANDING_VENSI.jpg"
        alt="VENSI 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/06_BRANDING_VENSI.jpg"
        alt="VENSI 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/07_BRANDING_VENSI.jpg"
        alt="VENSI 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/08_BRANDING_VENSI.jpg"
        alt="VENSI 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/09_BRANDING_VENSI.jpg"
        alt="VENSI 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/10_BRANDING_VENSI.jpg"
        alt="VENSI 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/11_BRANDING_VENSI.jpg"
        alt="VENSI 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/12_BRANDING_VENSI.jpg"
        alt="VENSI 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/13_BRANDING_VENSI.jpg"
        alt="VENSI 13"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/01_BRANDING_VENSI.jpg"
        alt="VENSI 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/02_BRANDING_VENSI.jpg"
        alt="VENSI 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/03_BRANDING_VENSI.jpg"
        alt="VENSI 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/04_BRANDING_VENSI.jpg"
        alt="VENSI 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/05_BRANDING_VENSI.jpg"
        alt="VENSI 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/06_BRANDING_VENSI.jpg"
        alt="VENSI 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/07_BRANDING_VENSI.jpg"
        alt="VENSI 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/08_BRANDING_VENSI.jpg"
        alt="VENSI 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/09_BRANDING_VENSI.jpg"
        alt="VENSI 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/10_BRANDING_VENSI.jpg"
        alt="VENSI 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/11_BRANDING_VENSI.jpg"
        alt="VENSI 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/12_BRANDING_VENSI.jpg"
        alt="VENSI 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/21_BRANDING_VENSI/13_BRANDING_VENSI.jpg"
        alt="VENSI 13"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Apuntes = props => (
  <ModalStruct
    text="This project was an opportunity to honor women, especially those brave enough to stand against sexism in a country where men have historically undermined them. On the book's cover, the naked woman depicts the naturality and impudence of the author's words throughout her poems. She’s also holding the weight of her words, representing the strength it takes to speak up about things that generally aren't said. And finally, the mirror effect intends for other women or men to empathize with the author’s cause as they see their reflection in the book."
    header="APUNTES"
    projectType="branding"
    name="APUNTES"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/01_BRANDING_APUNTES.jpg"
        alt="APUNTES 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/02_BRANDING_APUNTES.jpg"
        alt="APUNTES 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/03_BRANDING_APUNTES.jpg"
        alt="APUNTES 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/04_BRANDING_APUNTES.jpg"
        alt="APUNTES 4"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/01_BRANDING_APUNTES.jpg"
        alt="APUNTES 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/02_BRANDING_APUNTES.jpg"
        alt="APUNTES 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/03_BRANDING_APUNTES.jpg"
        alt="APUNTES 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/22_BRANDING_APUNTES/04_BRANDING_APUNTES.jpg"
        alt="APUNTES 4"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Altesa = props => (
  <ModalStruct
    text="Since it became a global company after being acquired by an international corporation, Altesa needed an outstanding presence on the internet. So, I gathered all available information to design a product catalog, which would be the central part of the website, and developed different kinds of content (graphic, video, copywriting…) to build a fun and informative nice-to-look-at website, both in English and Spanish. It’s worth mentioning that this website wasn’t a part of a digital marketing strategy, nor was it promoted other than what a good SEO structure did to position it. Regardless of that, it had +100 daily visitors and a 5% conversion rate."
    header="ALTESA"
    projectType="digitalDesign"
    name="ALTESA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/01_DIGITAL_ALTESA.jpg"
        alt="ALTESA 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/02_DIGITAL_ALTESA.jpg"
        alt="ALTESA 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/03_DIGITAL_ALTESA.jpg"
        alt="ALTESA 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/04_DIGITAL_ALTESA.jpg"
        alt="ALTESA 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/05_DIGITAL_ALTESA.jpg"
        alt="ALTESA 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/06_DIGITAL_ALTESA.jpg"
        alt="ALTESA 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/07_DIGITAL_ALTESA.jpg"
        alt="ALTESA 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/08_DIGITAL_ALTESA.jpg"
        alt="ALTESA 8"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/01_DIGITAL_ALTESA.jpg"
        alt="ALTESA 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/02_DIGITAL_ALTESA.jpg"
        alt="ALTESA 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/03_DIGITAL_ALTESA.jpg"
        alt="ALTESA 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/04_DIGITAL_ALTESA.jpg"
        alt="ALTESA 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/05_DIGITAL_ALTESA.jpg"
        alt="ALTESA 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/06_DIGITAL_ALTESA.jpg"
        alt="ALTESA 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/07_DIGITAL_ALTESA.jpg"
        alt="ALTESA 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/08_DIGITAL_ALTESA.jpg"
        alt="ALTESA 8"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ccnt = props => (
  <ModalStruct
    text="After successfully redesigning its corporate identity, this client was ready to take another step forward. I was trusted with a remodeling project for the second time, this time of a school’s façade. Since there was a structure involved, I hired an engineer and his team who were in charge of bringing my design to life. As you can notice, there was a significant change between the before and after of the project. "
    header="CCTN"
    projectType="branding"
    name="CCTN"
    images={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/01_BRANDING_CCTN.jpg"
        alt="CCTN 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/02_BRANDING_CCTN.jpg"
        alt="CCTN 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/03_BRANDING_CCTN.jpg"
        alt="CCTN 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/04_BRANDING_CCTN.jpg"
        alt="CCTN 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/05_BRANDING_CCTN.jpg"
        alt="CCTN 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/06_BRANDING_CCTN.jpg"
        alt="CCTN 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/07_BRANDING_CCTN.jpg"
        alt="CCTN 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/08_BRANDING_CCTN.jpg"
        alt="CCTN 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/09_BRANDING_CCTN.jpg"
        alt="CCTN 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/10_BRANDING_CCTN.jpg"
        alt="CCTN 10"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/01_BRANDING_CCTN.jpg"
        alt="CCTN 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/02_BRANDING_CCTN.jpg"
        alt="CCTN 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/03_BRANDING_CCTN.jpg"
        alt="CCTN 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/04_BRANDING_CCTN.jpg"
        alt="CCTN 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/05_BRANDING_CCTN.jpg"
        alt="CCTN 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/06_BRANDING_CCTN.jpg"
        alt="CCTN 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/07_BRANDING_CCTN.jpg"
        alt="CCTN 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/08_BRANDING_CCTN.jpg"
        alt="CCTN 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/09_BRANDING_CCTN.jpg"
        alt="CCTN 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/01 BRANDING/24_BRANDING_CCTN/10_BRANDING_CCTN.jpg"
        alt="CCTN 10"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Rioja = props => (
  <ModalStruct
    text="I used to live at this place in Guadalajara, Mexico. So, I offered to help a neighbor who wanted to run for president of the homeowner’s association with his campaign. Since the goal was to increase the collection of maintenance fees, I worked on an internal communication strategy that included the main entrance remodeling, security measures, a digital app to streamline payments, and an online gazette, among others… My neighbor lost, but the new committee came to me and asked for a new version of the strategy. We couldn’t agree on the budget, and it seemed like they only wanted free advisory and brainstorming. So, I stepped back."
    header="LA RIOJA"
    projectType="strategy"
    name="LA RIOJA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/01_STRATEGY_LARIOJA.jpg"
        alt="la rioja 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/02_STRATEGY_LARIOJA.jpg"
        alt="la rioja 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/03_STRATEGY_LARIOJA.jpg"
        alt="la rioja 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/04_STRATEGY_LARIOJA.jpg"
        alt="la rioja 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/05_STRATEGY_LARIOJA.jpg"
        alt="la rioja 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/06_STRATEGY_LARIOJA.jpg"
        alt="la rioja 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/07_STRATEGY_LARIOJA.jpg"
        alt="la rioja 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/08_STRATEGY_LARIOJA.jpg"
        alt="la rioja 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/09_STRATEGY_LARIOJA.jpg"
        alt="la rioja 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/10_STRATEGY_LARIOJA.jpg"
        alt="la rioja 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/11_STRATEGY_LARIOJA.jpg"
        alt="la rioja 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/12_STRATEGY_LARIOJA.jpg"
        alt="la rioja 12"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/01_STRATEGY_LARIOJA.jpg"
        alt="la rioja 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/02_STRATEGY_LARIOJA.jpg"
        alt="la rioja 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/03_STRATEGY_LARIOJA.jpg"
        alt="la rioja 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/04_STRATEGY_LARIOJA.jpg"
        alt="la rioja 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/05_STRATEGY_LARIOJA.jpg"
        alt="la rioja 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/06_STRATEGY_LARIOJA.jpg"
        alt="la rioja 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/07_STRATEGY_LARIOJA.jpg"
        alt="la rioja 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/08_STRATEGY_LARIOJA.jpg"
        alt="la rioja 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/09_STRATEGY_LARIOJA.jpg"
        alt="la rioja 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/10_STRATEGY_LARIOJA.jpg"
        alt="la rioja 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/11_STRATEGY_LARIOJA.jpg"
        alt="la rioja 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/04 STRATEGY/25_STRATEGY_LARIOJA/12_STRATEGY_LARIOJA.jpg"
        alt="la rioja 12"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Gscp = props => (
  <ModalStruct
    text="This is a straightforward app that helps GSCP manage its clients’ waste and visualize where it all went, creating reports and statistics and letting them know when eco-friendly milestones are achieved so they can acknowledge their employees and social network communities."
    header="GSCP"
    projectType="digitalDesign"
    name="GSCP"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/01_DIGITAL_GSCP.jpg"
        alt="GSCP 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/02_DIGITAL_GSCP.jpg"
        alt="GSCP 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/03_DIGITAL_GSCP.jpg"
        alt="GSCP 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/04_DIGITAL_GSCP.jpg"
        alt="GSCP 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/05_DIGITAL_GSCP.jpg"
        alt="GSCP 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/06_DIGITAL_GSCP.jpg"
        alt="GSCP 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/07_DIGITAL_GSCP.jpg"
        alt="GSCP 7"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/01_DIGITAL_GSCP.jpg"
        alt="GSCP 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/02_DIGITAL_GSCP.jpg"
        alt="GSCP 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/03_DIGITAL_GSCP.jpg"
        alt="GSCP 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/04_DIGITAL_GSCP.jpg"
        alt="GSCP 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/05_DIGITAL_GSCP.jpg"
        alt="GSCP 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/06_DIGITAL_GSCP.jpg"
        alt="GSCP 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/07_DIGITAL_GSCP.jpg"
        alt="GSCP 7"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const Ppa = props => (
  <ModalStruct
    text="As part of an internal communication strategy to become a Great Place To Work®, we intended to increase the levels of communication by providing a friendly platform where employees could establish straightforward communication with management and vice versa. I was a product manager and the UX/UI designer for this project."
    header="GRUPO PPA"
    projectType="digitalDesign"
    name="GRUPO PPA"
    images={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/01_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 1"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/02_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 2"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/03_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 3"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/04_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 4"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/05_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 5"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/06_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 6"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/07_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 7"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/08_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 8"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/09_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 9"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/10_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 10"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/11_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 11"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/12_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 12"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/13_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 13"
        layout="fullWidth"
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/14_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 14"
        layout="fullWidth"
      />,
    ]}
    thumbnails={[
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/01_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 1"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/02_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 2"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/03_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 3"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/04_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 4"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/05_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 5"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/06_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 6"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/07_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 7"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/08_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 8"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/09_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 9"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/10_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 10"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/11_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 11"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/12_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 12"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/13_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 13"
        width={100}
        height={100}
      />,
      <StaticImage
        src="../Briefcase/project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/14_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="GRUPO 14"
        width={100}
        height={100}
      />,
    ]}
    {...props}
  />
)

const ModalImage = ({ image, setData, size, modalData, index, page }) => {
  const ref = useRef()

  const setActive = () => {
    setData({
      ...modalData,
      index,
      offsetLeft: ref.current?.offsetLeft,
      offsetTop: ref.current?.offsetTop,
    })
  }

  useEffect(() => {
    if (modalData.index === index) {
      setActive()
    }
  }, [size, page])

  return (
    <ModalImageStyled ref={ref} onClick={setActive}>
      {image}
    </ModalImageStyled>
  )
}

const ModalStruct = ({
  header,
  closeModal,
  images,
  thumbnails,
  size,
  text,
  modalData,
  setData,
}) => {
  const [page, setPage] = useState(0)

  const moveImage = currentImage => {
    const totalImages = images.length
    if (currentImage > totalImages - 1) {
      setData({
        ...modalData,
        index: 0,
      })
      setPage(0)
    } else if (currentImage < 0) {
      setData({
        ...modalData,
        index: totalImages - 1,
      })
      setPage(totalImages - 1)
    } else {
      setData({
        ...modalData,
        index: currentImage,
      })
      setPage(currentImage)
    }
  }
  return (
    <Container>
      <SubContainer>
        <ModalHeader>
          <h4>{header}</h4>
          <CursorPointerDiv onClick={closeModal}>
            <Times />
          </CursorPointerDiv>
        </ModalHeader>
        <ModalBody>
          <ModalMainImage>
            <Arrows>
              <div onClick={() => moveImage((modalData.index || 0) + 1)}>
                <img src={arrowRight} alt="arrow right" />
              </div>
              <div onClick={() => moveImage((modalData.index || 0) - 1)}>
                <img src={arrowLeft} alt="arrow left" />
              </div>
            </Arrows>
            {images[modalData.index || 0]}
          </ModalMainImage>
          <ModalRightImages>
            <ModalImageSelector
              style={{
                left: modalData?.offsetLeft || 0,
                top: modalData?.offsetTop || 0,
              }}
            />
            {thumbnails.map((image, key) => (
              <ModalImage
                image={image}
                key={key}
                index={key}
                modalData={modalData}
                setData={setData}
                size={size}
                page={page}
              />
            ))}
          </ModalRightImages>
        </ModalBody>
        <Text>{text}</Text>
      </SubContainer>
    </Container>
  )
}

function Modal({ setData, modalData, closeModal }) {
  useEffect(() => {
    if (modalData.modal === null) {
      closeModal()
    }
    console.log(modalData.modal)
  }, [modalData.modal])

  const size = useWindowSize()

  const props = {
    closeModal,
    setData,
    modalData,
    size,
  }

  return (
    <>
      <GlobalStyle />
      <ModalWrapper isOpen={modalData.modal}>
        <Blured isOpen={modalData.modal} />
        <ModalContainer isOpen={modalData.modal}>
          {
            {
              Nomi0: <Nomi0 {...props} />,
              CityMedia0: <CityMedia0 {...props} />,
              Altesa0: <Altesa0 {...props} />,
              TopDoc0: <TopDoc0 {...props} />,
              Nomi1: <Nomi1 {...props} />,
              Nomi2: <Nomi2 {...props} />,
              Nomi3: <Nomi3 {...props} />,
              Altesa2: <Altesa2 {...props} />,
              Citymedia: <Citymedia {...props} />,
              Ikigai: <Ikigai {...props} />,
              Ppa0: <Ppa0 {...props} />,
              Nomi: <Nomi {...props} />,
              Ppa1: <Ppa1 {...props} />,
              Pich: <Pich {...props} />,
              TodDoc2: <TodDoc2 {...props} />,
              Media: <Media {...props} />,
              Ppa2: <Ppa2 {...props} />,
              Ppa3: <Ppa3 {...props} />,
              TocDoc: <TocDoc {...props} />,
              Wmg: <Wmg {...props} />,
              Vensi: <Vensi {...props} />,
              Apuntes: <Apuntes {...props} />,
              Altesa: <Altesa {...props} />,
              Ccnt: <Ccnt {...props} />,
              Rioja: <Rioja {...props} />,
              Gscp: <Gscp {...props} />,
              Ppa: <Ppa {...props} />,
            }[modalData.modal]
          }
        </ModalContainer>
      </ModalWrapper>
    </>
  )
}

export default Modal
